import styled from 'styled-components'
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import Pagination from "../components/pagination"
import DefaultHero from "../components/default-hero"

import '../css/events.css';

import YoastSeoParts from '../fragments/seo';

class EventTemplate extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    const webinars = this.props.data.allWpPastWebinar.nodes;

    const { currentPage, numPages } = this.props.pageContext

    let today = new Date();
    today = today.toISOString().substring(0, 10)

    return (
      <Layout>
        <PageWrapper accentColor={'#ffd300'}>
          <Head title={seo.title} description={seo.metaDesc} meta={seo} />
          <DefaultHero title={page.title} backgroundColor="#65869C" />

          <div className="container eventContainer">
            <div className="grid smallGrid">


              { webinars &&
                webinars.map(webinar => {
                  var backgroundStyles = {}

                  if(webinar.featuredImage)
                    backgroundStyles.backgroundImage = "url('"+webinar.featuredImage.node.localFile.publicURL+"')";

                  // if(event.cptPastWebinar.startDateTime.substring(0, 10) < today)
                  //   return;

                  return(
                    <div key={webinar.id} className="col-sm-6">
                      {webinar.cptE}
                      <div className="eventBox">
                        <Link to={webinar.link}>
                          <div className="eventBoxImage" style={backgroundStyles}></div>
                        </Link>
                        <div className="eventBoxBody">
                          <Link className="eventBoxTitle" to={webinar.link}>{webinar.title}</Link>
                          <div dangerouslySetInnerHTML={{ __html: webinar.excerpt }}></div>
                          <Link className="eventBoxLink" to={webinar.link}>Learn More</Link>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

              <Pagination uri={page.uri} currentPage={currentPage} numPages={numPages} />

            </div>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

const PageWrapper = styled.div`
  .defaultHero .dots span {
    color: ${({ accentColor }) => accentColor};
  }

  .defaultHero {
    border-top-color: ${({ accentColor }) => accentColor};
  }

  .eventBoxImage {
    border-color: ${({ accentColor }) => accentColor};
  }
`

export default EventTemplate

EventTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      link
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
    }

    allWpPastWebinar (
      filter: {status: {eq: "publish"}}
      sort: {fields: cptPastWebinar___startDateTime, order: DESC}
      limit: 30,
    ) {
      nodes {
        id
        title
        link
        excerpt
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
        cptPastWebinar {
          startDateTime
        }
      }
    }
  }
`